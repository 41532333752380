import { AttributeTypes } from '../../types/attribute/iAttribute';
import Select, { iSelect } from '../frameWork/Select';

export type iAttributeInputTypeSelector = Omit<
  iSelect<AttributeTypes>,
  'options' | 'value' | 'values'
> & {
  value?: AttributeTypes | null;
  values?: AttributeTypes[] | null;
};
const AttributeInputTypeSelector = ({
  value,
  values,
  ...props
}: iAttributeInputTypeSelector) => {
  const availOptions = Object.values(AttributeTypes).map((type) => ({
    label: type,
    value: type,
  }));

  const getSelectedValue = (val?: AttributeTypes | null) => {
    const valueStr = `${val || ''}`.trim();
    if (valueStr === '') {
      return undefined;
    }
    return { label: valueStr, value: valueStr };
  };

  const getSelectedValues = (vals?: AttributeTypes[] | null) => {
    const selectedVals = vals || [];
    if (selectedVals.length <= 0) {
      return undefined;
    }
    return selectedVals.map((value) => getSelectedValue(value));
  };
  return (
    <Select
      {...props}
      options={availOptions}
      value={getSelectedValue(value)}
      values={getSelectedValues(values)}
    />
  );
};

export default AttributeInputTypeSelector;
